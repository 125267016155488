import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmberScore from "../components/reuseable/AmberScore";

const Privacy = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Privacy Policy" />
    <div class="content has-text-centered columns flex-wrap mar-top-160">
            <h2 class="text-block-17 column is-12 is-paddingless">Privacy Policy</h2>
            <h4 class="has-text-weight-normal column is-12 is-paddingless">Everything you need to know</h4>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
                <h6>Introduction</h6>
                This Privacy Policy describes how we collect, use, process, and disclose your information, including personal information, in conjunction with your access to and use of our website, <a href="https://infeedo.com" target="_blank" rel="noopener noreferrer">www.infeedo.com</a> (“Website”), our product, “Amber” and the software as services provided to You, our client/customers, and the license/access granted to You in order to use our services ( collectively “Services”). 
                <br/><br/>
                If you see an undefined term in this Privacy Policy, it has the same definition as in our Terms of Service (for the Website) and Terms of Use of our Product (as available on the Website).
                <br/><br/>
                When this policy mentions “inFeedo,” “we,” “us,” or “our” it refers to the company (inFeedo Tech Inc/inFeedo Tech Private Limited/ inFeedo Private Limited, as the case maybe) that is responsible for your information under this Privacy Policy.
                <br/><br/>
                The Website may use a third-party payment gateway (either on the website and/or via a payment link that may be directly shared with you), and you will be also providing your information, including personal information, to the same when you are making a payment towards the Fee for licensing our product and/or making payment against the invoices that have been generated and raised against you and/or making any other ad-hoc payment that you may be required to make on the Website. Your private and personal information to such payment gateway shall be governed by that gateways’ own privacy policy which you may access from the payments page.
                <br/>
                <ol className="mg-left-1">
                    <li>
                        <b className="has-text-dark">Information we collect</b>
                        <ol>
                            <li><u>Information You Give to Us.</u> Information that is necessary for the use of the Website and/or the Services. We ask for and collect your personal information about you, or in case of an employee of a client company, then the details of such company (if applicable) when you use the Website and/or our Services. This information is necessary for the adequate performance of the contract between you and us and to allow us to comply with our legal obligations. Without it, we may not be able to provide you with all the requested Services, including use of the Website.</li>

                            <li><u>Account Information.</u> When you sign up on the Website, we require certain information such as your first name, last name, and email address. To use certain features of the Website and/or our Services, we may ask you to provide additional information, which may include your address, and phone number.</li>

                            <li><u>Payment Information.</u> To use certain features of the Website (such as to make payment towards the license fee/products and/or Services (as and when applicable), we may require you to provide certain financial information (like your bank account or credit card information) in order to facilitate the processing of payments via the payment gateway and/or via a payments link that we may send. If and when you give us Payment information, we use it solely to check your financial qualifications and collect payment from you. We may in the future use a third-party service provider to manage credit card processing. Whenever a service provider is on-boarded, he won't be permitted to store, retain, or use the information you provide except for the sole purpose of payment processing on our behalf.</li>

                            <li><u>Communications with inFeedo.</u> When you communicate with inFeedo or use the Website and/or product to communicate with us, we collect information about your communication and any information you choose to provide.</li>

                            <li><u>Information you choose to give us.</u> You may choose to provide us with additional personal information in order to obtain a better user experience when using the Website. This additional information will be processed based on your consent. In the event of information provided via the use of the product/software to utilise the Services, the same is with your consent by way of accepting the Services through a signed contract/accepted terms of service for the use/licensing of our product to you.</li>

                            <li><u>Other Information.</u> You may otherwise choose to provide us information when you fill in a form, conduct a search, respond to surveys, post to community forums, participate in promotions, or use other features on the Website (if available and applicable).</li>

                            <li><u>Information provided to the payment gateway. (when activated, on boarded and as and when applicable).</u> The payment gateway may require the information as is necessary for the adequate performance of the contract with you and to comply with applicable law (such as anti-money laundering regulations). Without it, you will not be able to use the payment gateway. Such information that is collected will be controlled by and shall be liable to be protected by the payment gateway provider under its own terms of service and privacy policy.</li>
                            
                            <li><u>Information We Automatically Collect from Your Use of the Website.</u> When you use the Website, we automatically collect information, including personal information, about the services you use and how you use them. This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Website.</li>

                            <li><u>Geo-location Information.</u> When you use certain features of the Website, we may collect information about your precise or approximate location as determined through data such as your IP address or mobile device’s GPS to offer you an improved user experience. Most mobile devices allow you to control or disable the use of location services for applications in the device’s settings menu. The Website may also collect this information even when you are not using the app if this connection is enabled through your settings or device permissions.</li>

                            <li><u>Usage Information.</u> We collect information about your interactions with the Website such as the pages or content you view, your searches for a product/service that is provided by inFeedo, and other actions on the Website.</li>

                            <li><u>Log Data and Device Information.</u> We automatically collect log data and device information when you access and use the Website, even if you have not created an account with inFeedo and/or the Website or logged in. That information includes, among other things: details about how you’ve used the Website (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Website.</li>

                            <li><u>Cookies and Similar Technologies.</u> We use cookies and other similar technologies. We may also allow our business partners to use these tracking technologies on the Website, or engage others to track your behavior on our behalf. Following are the cookies used by us - Google Analytics, HubSpot, Hotjar, Hellobar, frontapp, VWO.</li>
                            <li><u>Information We Collect from Third Parties.</u> inFeedo may collect information, including personal information, which others provide about you when they use the Website or reach the Website through a third-party, or obtain information from other sources and combine that with information we collect through the Website. We do not control, supervise or are responsible for how the third parties providing your information process your personal data, and any information request regarding the disclosure of your personal information to us should be directed to such third parties.</li>
                            
                            <li><u>Other Sources.</u> To the extent permitted by applicable law, we may receive additional information about you, such as demographic data or fraud detection information, from third party service providers and/or partners, and combine it with the information we have about you. We may receive information about you and your activities on and off the Website and/or via the Product use/ through partnerships, or about your experiences and interactions from our partner ad networks.</li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">How we use the information we Collect:</b><br/>
                        <b>Providing and Improving Our Service:</b>
                        <ol>
                            <li>We use, store, and process information, including personal information, about you to provide, understand, improve, and develop the Website and/or our product, create and maintain a trusted and safer environment and comply with our legal obligations and enable you to access and use the Website and/or our Services.</li>

                            <li>We may use the information to operate, protect, improve, and optimize the Website and/or our Services and experience, such as by performing analytics and conducting research.</li>

                            <li><u>Provide customer service.</u> Send you service or support messages, updates, security alerts, and account notifications.</li>

                            <li>If you provide us with your contact information, we may process this information: (i) to facilitate your referral invitations, (ii) send your requests for references, (iii) for fraud detection and prevention, (iv)to facilitate the use of the license and/or services purchased by you; and (v) for any purpose you authorize at the time of collection.</li>

                            <li>Create and Maintain a Trusted and Safer Environment.</li>

                            <li>Detect and prevent fraud, spam, abuse, security incidents, and other harmful activity.</li>

                            <li>Conduct security investigations and risk assessments.</li>
                            
                            <li>Verify or authenticate information or identifications provided by you.</li>

                            <li>Conduct checks against databases and other information sources, including background or police checks, to the extent permitted by applicable laws and with your consent where required.</li>

                            <li>Comply with our legal obligations.</li>

                            <li>Enforce our Terms of Service (for the use of Website) ; Terms of Service for use of our Product and other policies.</li>

                            <li>We process this information given our legitimate interest in protecting the Website and/or our Services, to measure the adequate performance of our contract with you, and to comply with applicable laws.</li>

                            <li>We may use the information shared by you to send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences (including information about inFeedo or partner campaigns and services) and social media advertising through social media platforms such as Facebook or Google).</li>

                            <li>We will process your personal information for the purposes listed in this section given our legitimate interest in undertaking marketing activities to offer you products or services that may be of your interest. You can opt-out of receiving marketing communications from us by following the unsubscribe instructions included in our marketing communications.</li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">How we use information we collect:</b><br/>
                        <ol>
                            <li><u>With Your Consent.</u>Where you have provided consent, we share your information, including personal information, as described at the time of consent, such as when you authorize a third party application or website to access your registered account or when you participate in promotional and/or other activities conducted by inFeedo partners or third parties.</li>

                            <br/><li>Compliance with Law, Responding to Legal Requests, Preventing Harm and Protection of our Rights. inFeedo may disclose your information, including personal information, to courts, law enforcement or governmental authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or if such disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims asserted against inFeedo, (iii) to respond to verified requests relating to a criminal investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, (iv) to enforce and administer our Terms of Service (to use the Website) and our Terms of Service to use the product, or (v) to protect the rights, property or personal safety of inFeedo, its employees, its affiliates, directors or members of the public at large.</li>

                            <br/><li>These disclosures may be necessary to comply with our legal obligations, for the protection of your or another person's vital interests or for the purposes of our or a third party’s legitimate interest in keeping the Website secure, preventing harm or crime, enforcing or defending legal rights, or preventing damage. Where appropriate, we may notify you about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon inFeedo and its Website and/or its Software applications. In instances where we comply with legal requests without notice for these reasons, we will attempt to notify that guest about the request after the fact where appropriate and where we determine in good faith that we are no longer prevented from doing so.</li>
                            <br/>

                            <li><u>Service Providers.</u> inFeedo uses a variety of third-party service providers to help us provide services related to the Website and/or our Services pertaining to the use and access of our product/software. These service providers may help us to perform product development, maintenance and debugging, allow the provision of our services through third party platforms and software tools, or provide customer service, advertising, or payments services or delivery services. These providers have limited access to your information to perform these tasks on our behalf, and are required to protect and to use it only for the purposes for which it was disclosed and consistent with this Privacy Policy.</li>

                            <br/><li><u>Social Media Platforms.</u> Where permissible according to applicable law, we may use certain limited personal information about you, such as your email address, to hash it and to share it with social media platforms, such as Facebook or Google, to generate leads, drive traffic to our websites or otherwise promote our products and services or the Website. These processing activities are based on our legitimate interest in undertaking marketing activities to offer you products or services that may be of your interest. The social media platforms with which we may share your personal data are not controlled or supervised by inFeedo. Therefore, any questions regarding how your social media platform service provider processes your personal data should be directed to such provider.

                            <br/> Please note that you may, at any time, ask inFeedo to cease processing your data for these direct marketing purposes by sending an e-mail to <a href="mailto:privacy@infeedo.com">privacy@infeedo.com</a>.</li>
                            <br/>
                            <li><u>Testimonials.</u> To the extent we share customer testimonials/comments/reviews on any of our Websites that contain personal information, we obtain any required consent(s) prior to posting the testimonial.</li>
                            <br/>
                            <li><u>Chat.</u> We use a chat feature to help you with any questions or concerns. If our chat feature is offline we may still collect your name, email address, and telephone number so that we may reply to your request.</li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">Other important information:</b><br/>
                        <ul>
                            <li><u>Analyzing your Communications.</u> We may review, scan, or analyze your communications on the Website for fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, and customer support purposes. In some cases, we may also scan, review, or analyze messages to debug, improve, and expand product offerings. We will not review, scan, or analyze your communications to send third party marketing messages to you, and we will not sell reviews or analyses of these communications.</li>

                            <li><u>Google Maps/Earth</u>. Parts of the Website may use Google Maps/Earth services, including the Google Maps API(s). Use of Google Maps/Earth is subject to Google Maps/Earth Additional Terms of Use and the Google Privacy Policy.</li>
                        </ul>
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">Third party partners & integration:</b><br/><br/>
                        The Website may contain links to third party websites or services, such as third party integrations, co-branded services, or third party-branded services (“<b>Third Party Partners</b>”). inFeedo doesn’t own or control these Third Party Partners and when you interact with them, you may be providing information directly to the Third Party Partner, inFeedo, or both. These Third Party Partners will have their own policies for use, protection and disclosure of personal information. We encourage you to review the privacy policies of the other websites you visit.

                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">The General Data Protection Regulation Compliances:</b><br/><br/>
                        The General Data Protection Regulation (“GDPR”) (EU) 2016/679 is a regulation in EU law on data protection and privacy for all individuals within the European Union. It also addresses the export of personal data outside the EU. The GDPR places limits on the gathering and use of personal data and establishes clear responsibilities for companies that collect it, as well as clear rights for people whose data is gathered. inFeedo is compliant to the GDPR, and if you are an EU resident, then you may access inFeedo’s Privacy Policy applicable to the <a  target="_blank" rel="noopener noreferrer" href="https://infeedo.com/gdpr/">EU Residents</a>. 


                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">The California Consumer Privacy Act (CCPA):</b><br/><br/>
                        inFeedo is both a “business” and a “service provider” under the CCPA. This Section 7 applies to information we collect in our role as a business, or when we interact directly with you. If you have any questions or would like to request access or deletion of your personal information please reach out to us at <a href="mailto:privacy@infeedo.com">privacy@infeedo.com</a>. 
                        <br/>
                        <br/>
                        <b>Your Rights under CCPA</b> If you are a California resident, there are some additional rights that may be available to you under the California Consumer Protection Act (“CCPA”). When we act as a business, you can exercise your rights under the CCPA by following the terms stated in this Privacy Policy.  
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">Lei Geral de Proteção de Dados Pessoais (LGPD):</b><br/><br/>
                        The official name of Brazil’s new data protection law is Lei Geral de Proteção de Dados Pessoais, which  means “general law of personal data protection”. It is officially abbreviated to LGPDP.
                        <br/>
                        <br/>
                        inFeedo is both a “business” and a “service provider” under the LGPD. This Section 8 applies to information we collect in our role as a business, or when we interact directly with you. If you have any questions or would like to request access or deletion of your personal information please reach out to us at <a href="mailto:privacy@infeedo.com">privacy@infeedo.com</a>. 
                        <br/>
                        <br/>
                        <b>Your Rights under LGPD</b> If you are a Brazil resident, there are some additional rights that may be available to you under the LGPD. When we act as a business, you can exercise your rights under the LGPD by following the terms stated in this Privacy Policy.
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">Your rights:</b><br/>
                        <ol>
                            <li>You are responsible for keeping your personal information up-to-date. You have the right to ask us to correct inaccurate or incomplete personal information concerning you.</li>
                            <li>In some jurisdictions, applicable law may entitle you to request copies of your personal information held by us. We generally retain your personal information forever in order to comply with our legal obligations. If you no longer want us to use your information to provide the Website to you, you can request that we erase your personal information. Please note that if you request the erasure of your personal information:
                                <ul>
                                    <li>We may retain some of your personal information as necessary for our legitimate business interests;</li>
                                    <li>We may retain and use your personal information to the extent necessary to comply with our legal obligations;</li>
                                    <li>Information you have shared with others (e.g., Reviews, forum postings) may continue to be publicly visible.</li>
                                </ul>
                            </li>
                            <li><u>Withdrawing Consent and Restriction of Processing</u>.  Where you have provided your consent to the processing of your personal information by inFeedo you may withdraw your consent by sending an e-mail to <a href="mailto:privacy@infeedo.com">privacy@infeedo.com</a>. Please note that the withdrawal of your consent does not affect the lawfulness of any processing activities based on such consent before its withdrawal.</li>

                            <li>We do not sell your personal information for processing by direct marketing agencies. We may however use your personal information for certain specific purposes (including profiling) where such processing is based on legitimate interest. If you object to such processing inFeedo will no longer process your personal information for these purposes unless we can demonstrate compelling legitimate grounds for such processing or such processing is required for the establishment, exercise or defence of legal claims.</li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">Data protection and security:</b><br/>
                        <ol>
                            <li>inFeedo uses systems as prescribed under the applicable Indian Laws in order to protect the personal information shared by you. All necessary means have been employed to ensure that your personal information remains protected.</li>

                            <li>We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your credentials as shared with inFeedo via the Website have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorized use of the information shared by you with inFeedo, please contact us.</li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b className="has-text-dark">Changes to this privacy policy:</b><br/><br/>
                        inFeedo reserves the right to modify this Privacy Policy at any time in accordance with this provision. If we make changes to this Privacy Policy, we will post the revised Privacy Policy on the Website and update the “Last Updated” date at the top of this Privacy Policy. Your continued access to or use of the Website will be subject to the revised Privacy Policy.
                    </li>
                </ol>
                <b>Contact Us</b><br/><br/>
                If you have any questions or complaints about this Privacy Policy or inFeedo’s information handling practices, you may email us or contact us at:<br/>
                E-mail id: <a href="mailto:privacy@infeedo.com">privacy@infeedo.com</a><br/>
                Contact Information: Seema Pathak, Head - Compliance and Security
                <br/><br/>
                <b>These policies were last updated on August 31, 2020.</b>
            </div>
    </div>
    <AmberScore/>
  </Layout>
)

export default Privacy